import React, {useEffect, useState} from "react"
import Img from 'gatsby-image';
import RellaxWrapper from 'react-rellax-wrapper'
import {Link} from 'gatsby';
import { Helmet } from "react-helmet";
import { navigate } from "gatsby"
import { useInView } from 'react-intersection-observer';
import "../sass/pages/_blog-index.scss";
import styled from 'styled-components'
import { graphql } from 'gatsby'

const Hero = styled.div`
background:linear-gradient(to top, ${props => props.col1}, ${props => props.col2} );
`

const Blog = ({ data, pageContext }) => {
  const posts = data.allPosts.edges;
  const firstPost = data.firstPost;
  const cats = data.allWpBlogCat.nodes;
  const {totalPages, pageNumber, taxonomy, noIndexNoFollow} = pageContext;
  const slug = taxonomy;
  const [isActive, setIsActive] = useState('');

  //console.log(pageContext)
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
      document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
      document.querySelector('body').setAttribute('data-class', '')

      const currentPage = window.location.pathname.split("/").pop();
      setIsActive(slug);

      const hero = document.querySelector('.hero_post')
      const filters = document.querySelector('.filters')
      hero.classList.add('is_inview')
      filters.classList.add('is_inview')
      
    }
  }, [])
  
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const handleOnChange = (e) => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var option =  optionElement.getAttribute('data-slug');
    var cat =  optionElement.getAttribute('data-cat');
    
    navigate(option)
    setIsActive(cat);

  }



  return (
    <>  
      
      <div className={`blog-index taxonomy page-${pageNumber}`}>
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <div className="wrapper-large">      
          <Hero className={`hero_post`} ref={ref} col1={firstPost.acf_customColours.customColour1}  col2={firstPost.acf_customColours.customColour2}>
            <div className="scrolling_image">
              <div className="img_wrap" >
                {firstPost.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
               <Img fluid={firstPost.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid} alt={firstPost?.acf_customFeaturedImage?.customFeaturedImage?.altText} className="bg-cover BG bgStretch" />
              </div>
            </div>
            
            <RellaxWrapper wrapper='#container-scroller'  speed={6} className="post_info rellax">
              <div className="post_info_inner">
                <Link to={firstPost.link}>
                  <h2>{firstPost.title}</h2>
                </Link>
                <div className="user_info">
                  <div className="image_avatar">
                    <img src={firstPost.author.node.avatar.url.replace('http://','https://')} alt="User" />
                  </div>
                  <div className="user"><span>By </span> <Link to={'/author/'+firstPost.author.node.name.replace(/\s+/g, '-').toLowerCase()+'/'}>{firstPost.author.node.firstName}</Link></div>
                  <div className="date">{firstPost.date}</div>
                </div>
              </div>
            </RellaxWrapper>
          </Hero>
          
          <section ref={ref} className={`filters inview_offset ${inView ? 'is_inview' : ''}`}>
            <ul>
              <li><a href="/insights/">All Articles</a></li>
              {cats.map((cat) => {
                //cat.slug, slug)
                return(<li className={cat.slug === slug ? 'active' : ''}><Link to={'/insights/'+cat.slug+'/'} className={cat.slug}>{cat.name}</Link></li>)
              })}
            </ul>
            <select name="filters is_inview" onChange={(e) => handleOnChange(e)} defaultValue={isActive}>
              <option data-cat="All Case Studies" data-slug={'/insights/'} value="All Articles">All Articles</option>
              {cats.map((cat) => {
                return(<option data-slug={'/insights/'+cat.slug+'/'} selected={isActive === cat.slug} data-cat={cat.name} value={cat.name} >{cat.name}</option>)
              })}
            </select>
          </section>
          
          <section ref={ref} className={`the_posts inview_offset ${inView ? 'is_inview' : ''}`} >
            {posts.map((post) => {
              return(
                
                <article id="post-" className="post">
                  <Link className="image-link" to={post.node.link} title="">
                    {post?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                    <Img fluid={post?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid } alt={post?.node?.acf_customFeaturedImage?.customFeaturedImage?.altText} className="bg-cover BG bgStretch" />
                  </Link>
                  {post.node.blogCats.nodes.map((cat) => {
                    return(
                      <Link to={cat.link}><div className="label">{cat.slug}</div></Link>
                    )
                  })}
                  
                  <h2>
                    <Link to={post.node.link} title="">{post.node.title}</Link>
                  </h2>
                  <div className="author_date"><span>By </span>{post.node.author.node.name }<span> | {post.node.date }</span></div>   
                </article>               
              )
            })}
          </section>

          <section className="pagination">
            <div className="wp-pagenavi" role="navigation">
                {pageNumber !== 1 ?
                  <Link className="previouspostslink" rel="previous" to={pageNumber === 2 ? `/insights/${slug}/`: `/insights/${slug}/page/${pageNumber-1}/`} >Prev</Link>
                : ""
                }
                {Array.from({ length: totalPages }, (_, i) => (
                    i === pageNumber - 1 ? 
                        <span aria-current="page" className="current">{i + 1}</span>
                    : 
                    <Link
                    className="page larger"
                    to={`${i === 0 ? `/insights/${slug}` : `/insights/${slug}/page/`+ (i + 1)}/`}
                    >
                    {i + 1}
                    </Link>
                ))}
                {pageNumber !== totalPages ?
                    <Link className="nextpostslink" rel="next" to={`/insights/${slug}/page/${pageNumber+1}/`}>Next</Link>
                : ""
                }
                
            </div>
          </section>
	</div>
	</div>

    </>
  )
}


export const pageQuery = graphql`
  query TaxonomyArchive($offset: Int!, $postsPerPage: Int!, $taxonomy: String!) {
    allPosts: allWpBlog(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {blogCats: {nodes: {elemMatch: {slug: {eq: $taxonomy}}}}}
    ) {
        edges {
            node {
                acf_customColours {
                  customColour1
                  customColour2
                }
                title
                date(formatString: "Do MMMM YYYY")
                slug
                link
                id
                blogCats {
                nodes {
                    slug
                    link
                }
                }
                author {
                node {
                    avatar {
                    url
                    }
                    firstName
                    slug
                    name
                    uri
                }
                }
                acf_customFeaturedImage {
                customFeaturedImage {
                    altText
                    localFile {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                    }
                }
                }
            }
        }
    }
    firstPost: wpBlog(blogCats: {nodes: {elemMatch: {slug: {eq: $taxonomy}}}}) {
        
      acf_customColours {
        customColour1
        customColour2
      }
      title
      date(formatString: "Do MMMM YYYY")
      slug
      link
      id
      blogCats {
        nodes {
          slug
          link
        }
      }
      author {
        node {
          avatar {
            url
          }
          firstName
          slug
          name
          uri
        }
      }
      acf_customFeaturedImage {
        customFeaturedImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      } 
    }

    allWpBlogCat(sort: {order: ASC, fields: id}) {
        nodes {
            name
            slug
            count
        }
    }
  }
`


export default Blog
